<template>
  <v-navigation-drawer v-model="drawer">
    <v-list density="compact">
      <v-list-item class="pa-4" href="/">
        <v-img height="5vmin" :src="logoSrc"></v-img>
      </v-list-item>
      <v-divider class="my-2"></v-divider>
      <div v-for="item in menuItems" :key="item.title">
        <v-list-item v-if="!item.child" :href="item.route">
          <template v-slot:prepend>
            <v-icon :icon="item.icon"></v-icon>
          </template>
          <v-list-item-title
            style="
              word-wrap: break-word !important;
              white-space: unset !important;
            "
            >{{ item.title }}</v-list-item-title
          >
        </v-list-item>
        <v-list-group v-if="item.child" :value="item.title">
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props">
              <template v-slot:prepend>
                <v-icon :icon="item.icon"></v-icon>
              </template>
              <v-list-item-title
                style="
                  word-wrap: break-word !important;
                  white-space: unset !important;
                "
                >{{ item.title }}</v-list-item-title
              >
            </v-list-item>
          </template>
          <v-list-item
            v-for="child in item.child"
            :key="child.title"
            :href="child.route"
          >
            <template v-slot:prepend>
              <v-icon :icon="child.icon"></v-icon>
            </template>
            <v-list-item-title
              style="
                word-wrap: break-word !important;
                white-space: unset !important;
              "
              >{{ child.title }}</v-list-item-title
            >
          </v-list-item>
        </v-list-group>
      </div>
    </v-list>
  </v-navigation-drawer>
  <v-app-bar :elevation="15" rounded app>
    <v-app-bar-nav-icon @click.stop="drawClick"></v-app-bar-nav-icon>
    <v-toolbar-title>
      <!-- Dil Değiştirme -->
      <v-select
        v-model="$i18n.locale"
        :items="languages"
        item-title="text"
        item-value="value"
        dense
        style="width: 200px"
        variant="outlined"
        hide-details
        @update:model-value="changeLanguage"
        prepend-icon="mdi-translate"
        class="language-select"
      ></v-select>
    </v-toolbar-title>
    <template v-slot:append>
      <v-menu>
        <template v-slot:activator="{ props: menu }">
          <v-tooltip location="bottom">
            <template v-slot:activator="{ props: tooltip }">
              <v-list class="pointer" v-bind="mergeProps(menu, tooltip)">
                <v-list-item lines="two" class="py-4">
                  <template v-slot:append>
                    <v-icon icon="mdi-account-circle"></v-icon>
                  </template>
                  <v-list-item-title>{{ user.fullName }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </template>
            <span>{{ $t("kullanici_islemleri_icin_tiklayiniz") }}</span>
          </v-tooltip>
        </template>
        <v-list class="pa-0">
          <v-list-item class="logout" @click="navigate('/password_change')">
            <template v-slot:prepend>
              <v-icon icon="mdi-account-key-outline"></v-icon>
            </template>
            <v-list-item-title>{{ $t("sifremi_degistir") }}</v-list-item-title>
          </v-list-item>
          <v-list-item class="logout" @click="logOutFunc">
            <template v-slot:prepend>
              <v-icon icon="mdi-logout"></v-icon>
            </template>
            <v-list-item-title>{{ $t("cikis_yap") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-app-bar>
</template>
<script>
import { mergeProps } from "vue";
import logoSideBar from "../../../public/assets/img/quatro_white_logo.svg";
import { mapActions } from "pinia";
import { useUserStore } from "../../stores/UserStore";
import { useI18nStore } from "../../stores/i18nStore";
export default {
  props: ["menuItems", "title", "user"],
  data() {
    return {
      drawer: true,
      rail: false,
      logoSrc: logoSideBar,
      languages: [
        { text: "Türkçe", value: "tr" },
        { text: "English", value: "en" },
        { text: "Deutsch", value: "de" },
      ],
    };
  },
  methods: {
    ...mapActions(useUserStore, ["logout"]),
    ...mapActions(useI18nStore, ["setLocale"]),
    navigate(route) {
      this.$router.replace(route);
    },
    changeLanguage() {
      this.setLocale(this.$i18n.locale);
      this.$router.go();
    },
    drawClick() {
      this.drawer = !this.drawer;
    },
    mergeProps,
    async logOutFunc() {
      const response = await this.logout();
      if (response.status) {
        this.navigate("/login");
      }
    },
  },
};
</script>
<style scoped>
.v-navigation-drawer {
  color: #f0f0f0;
  background-color: #013166;
}
.v-list-group__items .v-list-item {
  -webkit-padding-start: calc(10%) !important;
  padding-inline-start: calc(10%) !important;
}
.v-app-bar {
  min-height: 7vh !important;
}
.logout:hover {
  background-color: #013166;
  color: #f0f0f0;
}
.pointer {
  cursor: pointer;
}
.language-select {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 0 8px;
}
</style>
