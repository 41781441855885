// stores/i18nStore.js
import { defineStore } from "pinia";
export const useI18nStore = defineStore({
  id: "i18n",
  state: () => ({
    locale:
      localStorage.getItem("locale") ||
      (navigator.language || navigator.languages[0]).split("-")[0] ||
      "en",
  }),
  actions: {
    setLocale(locale) {
      this.locale = locale;
      localStorage.setItem("locale", locale);
    },
  },
});
