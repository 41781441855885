<template>
  <sidebar-comp
    :user="user"
    :title="routeName"
    :menuItems="menuItems.menuItems"
  ></sidebar-comp>
  <v-main>
    <v-container fluid>
      <slot />
    </v-container>
    <footer-comp></footer-comp>
  </v-main>
</template>
<script>
import { mapState, mapActions } from "pinia";
import { useUserStore } from "../../stores/UserStore";
import SidebarComp from "./SidebarComp.vue";
import FooterComp from "./FooterComp.vue";
export default {
  components: {
    SidebarComp,
    FooterComp,
  },
  computed: {
    ...mapState(useUserStore, ["getUserInfo", "getAllUserAuth"]),
  },
  async created() {
    const menuArray = [
      {
        title: this.$t("yonetim_paneli"),
        icon: "mdi-cogs",
        route: "#",
        child: [
          {
            title: this.$t("rol_yonetimi"),
            icon: "mdi-account",
            route: "/#/roles",
          },
          {
            title: this.$t("kullanici_yonetimi"),
            icon: "mdi-account",
            route: "/#/users",
          },
          {
            title: this.$t("sayfa_yonetimi"),
            icon: "mdi-account",
            route: "/#/pages",
          },
        ],
      },
      {
        title: this.$t("bayi_dashboard"),
        icon: "mdi-store-outline",
        route: "/#/dealer/dashboard",
      },
      {
        title: this.$t("bayi_duraklari_listesi"),
        icon: "mdi-store-marker-outline",
        route: "/#/dealer_stations",
      },
      {
        title: this.$t("bayiler"),
        icon: "mdi-store-outline",
        route: "/#/dealers",
      },
      {
        title: this.$t("duraklar"),
        icon: "mdi-store-24-hour",
        route: "/#/stations",
      },
      { title: this.$t("araclar"), icon: "mdi-taxi", route: "/#/vehicles" },
      { title: this.$t("cihazlar"), icon: "mdi-devices", route: "/#/devices" },
      {
        title: this.$t("suruculer"),
        icon: "mdi-card-account-details",
        route: "/#/drivers",
      },
      {
        title: this.$t("arac_sahibi"),
        icon: "mdi-account-circle",
        route: "/#/owners",
      },
      {
        title: this.$t("tarife_servisi"),
        icon: "mdi-cogs",
        route: "#",
        child: [
          {
            title: "Tarife Listesi",
            icon: "mdi-list-box-outline",
            route: "/#/tariffs",
          },
        ],
      },
      {
        title: this.$t("tarife_servisi"),
        icon: "mdi-cogs",
        route: "#",
        child: [
          {
            title: this.$t("tarife_gecmisi_listeleme"),
            icon: "mdi-list-box-outline",
            route: "/#/tariff_histories",
          },
          {
            title: this.$t("tarife_tanimlama"),
            icon: "mdi-playlist-plus",
            route: "/#/tariff_match",
          },
        ],
      },
      {
        title: this.$t("envanter"),
        icon: "mdi-cogs",
        route: "#",
        child: [
          {
            title: this.$t("envanter_olcu_birimi_listesi"),
            icon: "mdi-list-box-outline",
            route: "/#/inventory_measurements",
          },
          {
            title: this.$t("envanter_durumu_listesi"),
            icon: "mdi-list-box-outline",
            route: "/#/inventory_status",
          },
          {
            title: this.$t("envanter_urun_turu_listesi"),
            icon: "mdi-list-box-outline",
            route: "/#/inventory_products",
          },
          {
            title: this.$t("envanter_marka_listesi"),
            icon: "mdi-list-box-outline",
            route: "/#/inventory_brands",
          },
          {
            title: this.$t("envanter_stok_karti_listesi"),
            icon: "mdi-list-box-outline",
            route: "/#/inventory_card_stocks",
          },
          {
            title: this.$t("envanter_listesi"),
            icon: "mdi-list-box-outline",
            route: "/#/inventories",
          },
        ],
      },
      {
        title: this.$t("servis_islemleri"),
        icon: "mdi-cogs",
        route: "#",
        child: [
          {
            title: this.$t("arac_islemleri"),
            icon: "mdi-list-box-outline",
            route: "/#/services/new_service_record",
          },
          {
            title: this.$t("bekleyen_servis_islemleri"),
            icon: "mdi-list-box-outline",
            route: "/#/services/waiting_processes",
          },
          {
            title: this.$t("tamamlanan_servis_islemleri"),
            icon: "mdi-list-box-outline",
            route: "/#/services/completed_processes",
          },
        ],
      },
      {
        title: this.$t("servis_islemleri") + " (MERKEZ)",
        icon: "mdi-cogs",
        route: "#",
        child: [
          {
            title: this.$t("arac_islemleri") + " (MERKEZ)",
            icon: "mdi-list-box-outline",
            route: "/#/main_services/new_service_record",
          },
          {
            title: this.$t("bekleyen_servis_islemleri") + " (MERKEZ)",
            icon: "mdi-list-box-outline",
            route: "/#/main_services/waiting_processes",
          },
          {
            title: this.$t("tamamlanan_servis_islemleri") + " (MERKEZ)",
            icon: "mdi-list-box-outline",
            route: "/#/main_services/completed_processes",
          },
        ],
      },
      {
        title: this.$t("onay_bekleyen_surucu_fotograflari"),
        icon: "mdi-account-circle",
        route: "/#/pending_approval_images",
      },
      {
        title: this.$t("data_alan_araclar_listesi"),
        icon: "mdi-list-box-outline",
        route: "/#/taximeter_data_dates",
      },
      // { title: 'Mesaj Gönder', icon: 'mdi-message-text-fast-outline', route: '/#/' },
    ];

    this.user.fullName = this.getUserInfo.name + " " + this.getUserInfo.surname;
    await this.getAllUserAuthsAction();
    for (const i of menuArray) {
      if (i.child) {
        const childArray = [];
        for (const y of i.child) {
          const response = this.getAllUserAuth.find((t) => {
            if (t.page.path === y.route.slice(2)) return y;
          });
          if (response) {
            if (response?.permissions.permissionName !== "Unauthorized") {
              childArray.push(y);
            }
          }
        }
        if (childArray.length > 0) {
          this.menuItems.menuItems.push(i);
          i.child = childArray;
        }
      } else {
        const response = this.getAllUserAuth.find((t) => {
          if (t.page.path === i.route.slice(2)) return t;
        });
        if (response) {
          if (response?.permissions.permissionName !== "Unauthorized") {
            this.menuItems.menuItems.push(i);
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(useUserStore, ["getAllUserAuthsAction"]),
  },
  data() {
    return {
      routeName: this.$route.name,
      user: {
        fullName: null,
      },
      menuItems: {
        menuItems: [],
      },
    };
  },
};
</script>
<style>
body {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.v-main {
  background-color: #e9ecef;
  min-height: 87vh !important;
}
.v-container {
  min-height: 87vh;
}
</style>
